.selection-page-flex-container {
  display: flex;
  justify-content: center; /* Centers the grid horizontally */
  align-items: center;     /* Centers the grid vertically */
  margin-bottom:50px;
  
}

.selection-page-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: transparent;
  margin-top: 16px;
}

.selection-page-grid-item {
  text-align: center;
  border: 1px solid #4712B1;
}

/* Center the last item if the number of items is odd */
.selection-page-grid-container::after {
  content: '';
  grid-column: span 2;
  display: block;
}