input::placeholder {
    color: '#EE72F1';
    font-size: 14;
    font-family: 'Inter';
    font-weight: '400';
    word-wrap: 'break-word'
  }
input::-webkit-input-placeholder {
    color: '#EE72F1';
    font-size: 14;
    font-family: 'Inter';
    font-weight: '400';
    word-wrap: 'break-word'
}